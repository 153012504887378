import { Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { AppBar, Container, Divider, Drawer, IconButton, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Header from './Header'
import Footer from './Footer'
import clsx from 'clsx';


import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 140;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'red'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const AboutNFT = () => {

  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  const getHeight = () => window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  const updateDevice = (width: number, setDevice: { (value: SetStateAction<string>): void; (arg0: string): void; }): void => {
    const isMobile = width >= 320 && width <= 480;
    const isTablet = width >= 481 && width <= 768;
    const isLaptop = width >= 769 && width <= 1024;
    const isDesktop = width >= 1025 && width <= 1200;
    const isLargeScreen = width >= 1201;


    if (isMobile) setDevice('mobile');
    if (isTablet) setDevice('tablet');
    if (isLaptop) setDevice('laptop');
    if (isDesktop) setDevice('desktop');
    if (isLargeScreen) setDevice('large screen');
  }

  function useCurrentWidth() {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());





    let [device, setDevice] = useState('');

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {

      const width = getWidth();
      updateDevice(width, setDevice);

      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);

        const width = getWidth();

        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setWidth(width), 150);

        updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])

    return [width, device];
  }

  function useCurrentHeight() {
    // save current window height in the state object
    let [height, setHeight] = useState(getHeight());





    // let [device, setDevice] = useState('');

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {

      // const height = getHeight();
      // updateDevice(width, setDevice);

      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);

        const height = getHeight();

        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setHeight(height), 150);

        // updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])

    return [height, device];
  }


  let [width, device] = useCurrentWidth();
  let [height] = useCurrentHeight();




  return (

    <div>
      {(device === 'mobile') ? (
        <div className={classes.root}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: openDrawer,
            })}
          >
            <Toolbar style={{ background: '#1f1f21' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, openDrawer && classes.hide)}
              >
                <MenuIcon style={{ color: '#FFAA00' }} />
              </IconButton>
              <Typography variant="h6" noWrap style={{ color: '#FFAA00' }}>
                Cheese
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={openDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose} style={{ color: 'white' }}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider style={{ backgroundColor: 'white' }} />
            <List>
              {[
                {
                  name: 'Home',
                  url: '/home'
                },
                {
                  name: 'Stories',
                  url: '/stories'
                },
                {
                  name: 'About CC/Team',
                  url: '/about'
                },
                {
                  name: 'About NFTs',
                  url: '/about-nft'
                },
                {
                  name: 'Breeding',
                  url: '/breeding'
                },
              ].map((page, index) => (
                <ListItem button key={index}>
                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                  {/* <ListItemText primary={text} /> */}
                  <HashLink to={page.url} style={{ color: '#ffaa00' }}>{page.name}</HashLink>
                </ListItem>
              ))}
            </List>
            {/* <Divider />
            <List>
              {['All mail', 'Trash', 'Spam'].map((text, index) => (
                <ListItem button key={text}>
                  <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List> */}
          </Drawer>
          <main
            style={device === 'mobile' ? { padding: 0 } : {}}
            className={clsx(classes.content, {
              [classes.contentShift]: openDrawer,
            })}
          >
            <div className={classes.drawerHeader} />
            <div
              style={device === 'mobile' ? {
                width: '80vw',
                margin: 'auto',
                textAlign: 'center',
                marginLeft: 40,
              } : {
                width: '80vw',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <Paper
                style={{
                  padding: 24,
                  paddingBottom: 10,
                  backgroundColor: '#151A1F',
                  borderRadius: 6,
                  // textAlign: 'center',
                  color: '#FFAA00BF',
                  fontFamily: 'avenir',
                  lineHeight: 1.43
                }}
              >

                <p><strong><h3><u>NFTs, Crypto, and Blockchains</u></h3></strong></p>
                <p>To understand the fundamentals of NFTs, you must have a brief
                  understanding of cryptocurrency and blockchains.</p>

                <p>Cryptocurrency is an umbrella term for decentralized digital currencies that
                  have transactions publicly recorded on a ledger. Similar to how a bank
                  statement shows every transaction in and out of an account, a cryptocurrency
                  ledger shows every transaction for that specific currency all over the world.
                  These ledgers are known as blockchains.</p>

                <p>Every cryptocurrency has its own blockchain. Blockchains cannot be altered
                  and do not exist on a centralized platform. A blockchain exists on a network of
                  computers around the world and can be accessed by anyone. This is what
                  makes cryptocurrency decentralized, there’s no central group that controls
                  any currency.</p>

                <p>&quot;NFT&quot; stands for non-fungible token. Non-fungible means not able to be
                  exchanged for an identical item and a token is something that represents
                  something else.</p>

                <p>In other words, an NFT is a digital one-of-a-kind representation of something.</p>

                <p>When an NFT is purchased, that transaction is recorded on the blockchain.
                  Once recorded, this record acts as a verification for who the current owner is
                  of that NFT. So, when you purchase and NFT you&#39;re actually purchasing the
                  verification of ownership for that unique token.</p>
                <br />






                <p><strong><h3><u>What’s the Potential for NFTs?</u></h3></strong></p>
                <p>The most interesting concept behind NFTs is this proof of ownership. As
                  stated above, NFTs use blockchain technology to confirm the owner of a
                  token.</p>
                <p>
                  This technology can be used for anything that requires proof of ownership.
                  College degrees, passports, drivers’ licenses, a deed to a property, event
                  tickets and so many more everyday items can become NFTs.

                  The benefit would be having a convenient digital and verifiable copy of any
                  important documents or assets you own. Convenience is key to innovation.
                </p>
                <br />

                <p><strong><h3><u>NFT/Cryptocurrency Glossary</u></h3></strong></p>
                <p>Below are some of the popular terms, slang, and phrases used in the NFT
                  and cryptocurrency space. Some of these terms are also used for other
                  industries.</p>

                <p>
                  <u><b>airdrop:</b></u> the process of sending or receiving (a) free NFT(s) or cryptocurrency
                  <br />
                  <em>
                    (Airdropped NFTs can contain a malicious link. Be sure you know who the airdrop is from
                    or make sure the project you’re expecting an airdrop from has announced it
                    beforehand. Do not click links in airdrops from an unknown source.)
                  </em>
                </p>
                <p>
                  <u><b>alpha:</b></u> intelligent investment advice; insider information
                </p>
                <p>
                  <u><b>ape in:</b></u> to invest a large amount into one NFT project or one crytpocurrency
                </p>
                <p>
                  <u><b>bearish:</b></u> to feel confident about something decreasing in price or doing poorly
                </p>
                <p>
                  <u><b>blockchain:</b></u> a public ledger that displays every transaction for a particular
                  cryptocurrency, stored on networks of computers not one central platform
                </p>
                <p>
                  <u><b>bluechip:</b></u> a well-known and usually high-value NFT project (value is subjective)
                </p>
                <p>
                  <u><b>breeding:</b></u> the process of using two NFTs to either change the design of the first
                  NFT or create a third NFT <em>(example: The Bored Ape Yacht Club collection was
                    able to breed with the Bored Ape Chemistry Club collection [mutant serum NFTs]
                    to create the famous Mutant Ape Yacht Club collection.)</em>
                </p>
                <p>
                  <u><b>bullish:</b></u> to feel confident about something increasing in price or doing well
                </p>
                <p>
                  <u><b>CT:</b></u> Crypto Twitter; an open community of people on Twitter who discuss
                  cryptocurrencies and NFTs
                </p>
                <p>
                  <u><b>DAO:</b></u> "decentralized autonomous organization" an organization in which all
                  members have equal say on decisions
                </p>
                <p>
                  <u><b>DAPP:</b></u> "decentralized app" an application built on the blockchain (see above for
                  “blockchain”)
                </p>


                <p>
                  <u><b>degen:</b></u> short for degenerate; one who makes questionable investments;
                  everyone currently in the NFT space
                </p>
                <p>
                  <u><b>delisting:</b></u> the process of removing your NFT listing from a marketplace so it
                  cannot be purchased by a new buyer
                </p>
                <p>
                  <u><b>derivative:</b></u> an NFT project based on the art of another NFT project
                </p>


                <p>
                  <u><b>diamond hands:</b></u> holding on to an NFT for the long run regardless of a massive
                  price drop or increase
                </p>
                <p>
                  <u><b>doxx:</b></u> the process of revealing one’s identity or the identities of the NFT project’s
                  team
                </p>
                <p>
                  <u><b>DYOR:</b></u> "do your own research"
                </p>


                <p>
                  <u><b>floor price:</b></u> the minimum price of an NFT from that collection
                </p>
                <p>
                  <u><b>FOMO:</b></u> “fear of missing out”
                </p>
                <p>
                  <u><b>FUD:</b></u> "fear, uncertainty, doubt"
                </p>


                <p>
                  <u><b>gas fee:</b></u> the tax charged for having a transaction confirmed on the blockchain
                </p>
                <p>
                  <u><b>Gm:</b></u> good morning
                </p>
                <p>
                  <u><b>gmi:</b></u> “going to make it” going to do well
                </p>


                <p>
                  <u><b>HODL</b></u> “hold on for dear life” was originally a typo for “hold” similar meaning to
                  “diamond hands” (see above)
                </p>
                <p>
                  <u><b>irl:</b></u> "in real life"
                </p>
                <p>
                  <u><b>list:</b></u> the process of putting an NFT on a marketplace for resale
                </p>


                <p>
                  <u><b>marketplace</b></u> a platform used for buying a selling NFTs <em>(OpenSea, Magic Eden,
                    Rarible, etc.)</em>
                </p>
                <p>
                  <u><b>mint:</b></u> creating an NFT on the blockchain <em>(see above for “blockchain”)</em> the
                  process of buying an NFT directly from the smart contract or from the project’s
                  website
                </p>
                <p>
                  <u><b>mooning:</b></u> the increase in price of an NFT or NFT collection
                </p>



                <p>
                  <u><b>NFA</b></u> “not financial advice”
                </p>
                <p>
                  <u><b>ngmi:</b></u> “not going to make it;” not going to do well
                </p>
                <p>
                  <u><b>-or/-oor/-ooor:</b></u> a suffix used to replace “-er,” <em>(the number of “o’s” is arbitrary,
                    examples: foundor, tradoor, investor, buildooor, etc.)</em>
                </p>

                <p>
                  <u><b>paper hand:</b></u> selling an NFT quickly with the slightest increase or decrease in
                  price
                </p>


                <p>
                  <u><b>pfp</b></u> picture for profile; profile picture
                </p>
                <p>
                  <u><b>public key:</b></u> a string of letters and numbers used to receive NFTs or
                  cryptocurrency into your cryptocurrency wallet
                </p>
                <p>
                  <u><b>private key:</b></u> used to send NFTs or cryptocurrency from your cryptocurrency
                  wallet or approve transaction from your wallet <em>(if you’re ever hacked, this is what
                    the hacker will need to drain assets from your wallet)</em>
                </p>



                <p>
                  <u><b>pump and dump:</b></u> a type of rug pull (see below); the process of artificially
                  inflating the price of an NFT project with intent to sell to new buyers; a type of
                  scam usually using paid social media influencers to promote a project solely to
                  sell to unassuming victims without any means of the new price being maintained
                </p>
                <p>
                  <u><b>rug pull:</b></u> an NFT project that “pulls the rug” on its holders; usually disappears
                  after the initial mint sells out, may also leave slowly over time; an NFT project
                  that does not fulfill its promises; an NFT project that artificially inflates the price
                  of their collection with intent to sell without any means of the new price being
                  maintained
                </p>
                <p>
                  <u><b>sharding:</b></u> also known as “fractionalization;” the process of dividing an NFT so it
                  can have multiple owners
                </p>



                <p>
                  <u><b>ser:</b></u> sir
                </p>
                <p>
                  <u><b>shill:</b></u> promote
                </p>
                <p>
                  <u><b>staking:</b></u> the process of storing an NFT or cryptocurrency on a platform where
                  the owner can receive dividends
                </p>


                <p>
                  <u><b>sweep the floor:</b></u> the process of buying all the NFTs in a collection that are listed
                  at the floor price (see above for “floor price”)
                </p>
                <p>
                  <u><b>utility:</b></u> the value an NFT provides (examples: IP rights, access to an event,
                  discounts on future products, meet and greets, etc.)
                </p>
                <p>
                  <u><b>wagmi:</b></u> “we’re all going to make it;” we’re all going to do well
                </p>

                <p>
                  <u><b>wen:</b></u> when
                </p>
                <p>
                  <u><b>whale:</b></u> someone with a lot of money to invest
                </p>




              </Paper>
            </div>
          </main>
        </div>
      ) : (
        <>
          <Header />
          <Container style={{ marginTop: 100 }}>
            <Container maxWidth="md" style={{ position: 'relative' }}>
              <Paper
                style={{
                  padding: 24,
                  paddingBottom: 10,
                  backgroundColor: '#151A1F',
                  borderRadius: 6,
                  // textAlign: 'center',
                  color: '#FFAA00BF',
                  fontFamily: 'avenir',
                  lineHeight: 1.43
                }}
              >

                <p><strong><h3><u>NFTs, Crypto, and Blockchains</u></h3></strong></p>
                <p>To understand the fundamentals of NFTs, you must have a brief
                  understanding of cryptocurrency and blockchains.</p>

                <p>Cryptocurrency is an umbrella term for decentralized digital currencies that
                  have transactions publicly recorded on a ledger. Similar to how a bank
                  statement shows every transaction in and out of an account, a cryptocurrency
                  ledger shows every transaction for that specific currency all over the world.
                  These ledgers are known as blockchains.</p>

                <p>Every cryptocurrency has its own blockchain. Blockchains cannot be altered
                  and do not exist on a centralized platform. A blockchain exists on a network of
                  computers around the world and can be accessed by anyone. This is what
                  makes cryptocurrency decentralized, there’s no central group that controls
                  any currency.</p>

                <p>&quot;NFT&quot; stands for non-fungible token. Non-fungible means not able to be
                  exchanged for an identical item and a token is something that represents
                  something else.</p>

                <p>In other words, an NFT is a digital one-of-a-kind representation of something.</p>

                <p>When an NFT is purchased, that transaction is recorded on the blockchain.
                  Once recorded, this record acts as a verification for who the current owner is
                  of that NFT. So, when you purchase and NFT you&#39;re actually purchasing the
                  verification of ownership for that unique token.</p>
                <br />






                <p><strong><h3><u>What’s the Potential for NFTs?</u></h3></strong></p>
                <p>The most interesting concept behind NFTs is this proof of ownership. As
                  stated above, NFTs use blockchain technology to confirm the owner of a
                  token.</p>
                <p>
                  This technology can be used for anything that requires proof of ownership.
                  College degrees, passports, drivers’ licenses, a deed to a property, event
                  tickets and so many more everyday items can become NFTs.

                  The benefit would be having a convenient digital and verifiable copy of any
                  important documents or assets you own. Convenience is key to innovation.
                </p>
                <br />

                <p><strong><h3><u>NFT/Cryptocurrency Glossary</u></h3></strong></p>
                <p>Below are some of the popular terms, slang, and phrases used in the NFT
                  and cryptocurrency space. Some of these terms are also used for other
                  industries.</p>

                <p>
                  <u><b>airdrop:</b></u> the process of sending or receiving (a) free NFT(s) or cryptocurrency
                  <br />
                  <em>
                    (Airdropped NFTs can contain a malicious link. Be sure you know who the airdrop is from
                    or make sure the project you’re expecting an airdrop from has announced it
                    beforehand. Do not click links in airdrops from an unknown source.)
                  </em>
                </p>
                <p>
                  <u><b>alpha:</b></u> intelligent investment advice; insider information
                </p>
                <p>
                  <u><b>ape in:</b></u> to invest a large amount into one NFT project or one crytpocurrency
                </p>
                <p>
                  <u><b>bearish:</b></u> to feel confident about something decreasing in price or doing poorly
                </p>
                <p>
                  <u><b>blockchain:</b></u> a public ledger that displays every transaction for a particular
                  cryptocurrency, stored on networks of computers not one central platform
                </p>
                <p>
                  <u><b>bluechip:</b></u> a well-known and usually high-value NFT project (value is subjective)
                </p>
                <p>
                  <u><b>breeding:</b></u> the process of using two NFTs to either change the design of the first
                  NFT or create a third NFT <em>(example: The Bored Ape Yacht Club collection was
                    able to breed with the Bored Ape Chemistry Club collection [mutant serum NFTs]
                    to create the famous Mutant Ape Yacht Club collection.)</em>
                </p>
                <p>
                  <u><b>bullish:</b></u> to feel confident about something increasing in price or doing well
                </p>
                <p>
                  <u><b>CT:</b></u> Crypto Twitter; an open community of people on Twitter who discuss
                  cryptocurrencies and NFTs
                </p>
                <p>
                  <u><b>DAO:</b></u> "decentralized autonomous organization" an organization in which all
                  members have equal say on decisions
                </p>
                <p>
                  <u><b>DAPP:</b></u> "decentralized app" an application built on the blockchain (see above for
                  “blockchain”)
                </p>


                <p>
                  <u><b>degen:</b></u> short for degenerate; one who makes questionable investments;
                  everyone currently in the NFT space
                </p>
                <p>
                  <u><b>delisting:</b></u> the process of removing your NFT listing from a marketplace so it
                  cannot be purchased by a new buyer
                </p>
                <p>
                  <u><b>derivative:</b></u> an NFT project based on the art of another NFT project
                </p>


                <p>
                  <u><b>diamond hands:</b></u> holding on to an NFT for the long run regardless of a massive
                  price drop or increase
                </p>
                <p>
                  <u><b>doxx:</b></u> the process of revealing one’s identity or the identities of the NFT project’s
                  team
                </p>
                <p>
                  <u><b>DYOR:</b></u> "do your own research"
                </p>


                <p>
                  <u><b>floor price:</b></u> the minimum price of an NFT from that collection
                </p>
                <p>
                  <u><b>FOMO:</b></u> “fear of missing out”
                </p>
                <p>
                  <u><b>FUD:</b></u> "fear, uncertainty, doubt"
                </p>


                <p>
                  <u><b>gas fee:</b></u> the tax charged for having a transaction confirmed on the blockchain
                </p>
                <p>
                  <u><b>Gm:</b></u> good morning
                </p>
                <p>
                  <u><b>gmi:</b></u> “going to make it” going to do well
                </p>


                <p>
                  <u><b>HODL</b></u> “hold on for dear life” was originally a typo for “hold” similar meaning to
                  “diamond hands” (see above)
                </p>
                <p>
                  <u><b>irl:</b></u> "in real life"
                </p>
                <p>
                  <u><b>list:</b></u> the process of putting an NFT on a marketplace for resale
                </p>


                <p>
                  <u><b>marketplace</b></u> a platform used for buying a selling NFTs <em>(OpenSea, Magic Eden,
                    Rarible, etc.)</em>
                </p>
                <p>
                  <u><b>mint:</b></u> creating an NFT on the blockchain <em>(see above for “blockchain”)</em> the
                  process of buying an NFT directly from the smart contract or from the project’s
                  website
                </p>
                <p>
                  <u><b>mooning:</b></u> the increase in price of an NFT or NFT collection
                </p>



                <p>
                  <u><b>NFA</b></u> “not financial advice”
                </p>
                <p>
                  <u><b>ngmi:</b></u> “not going to make it;” not going to do well
                </p>
                <p>
                  <u><b>-or/-oor/-ooor:</b></u> a suffix used to replace “-er,” <em>(the number of “o’s” is arbitrary,
                    examples: foundor, tradoor, investor, buildooor, etc.)</em>
                </p>

                <p>
                  <u><b>paper hand:</b></u> selling an NFT quickly with the slightest increase or decrease in
                  price
                </p>


                <p>
                  <u><b>pfp</b></u> picture for profile; profile picture
                </p>
                <p>
                  <u><b>public key:</b></u> a string of letters and numbers used to receive NFTs or
                  cryptocurrency into your cryptocurrency wallet
                </p>
                <p>
                  <u><b>private key:</b></u> used to send NFTs or cryptocurrency from your cryptocurrency
                  wallet or approve transaction from your wallet <em>(if you’re ever hacked, this is what
                    the hacker will need to drain assets from your wallet)</em>
                </p>



                <p>
                  <u><b>pump and dump:</b></u> a type of rug pull (see below); the process of artificially
                  inflating the price of an NFT project with intent to sell to new buyers; a type of
                  scam usually using paid social media influencers to promote a project solely to
                  sell to unassuming victims without any means of the new price being maintained
                </p>
                <p>
                  <u><b>rug pull:</b></u> an NFT project that “pulls the rug” on its holders; usually disappears
                  after the initial mint sells out, may also leave slowly over time; an NFT project
                  that does not fulfill its promises; an NFT project that artificially inflates the price
                  of their collection with intent to sell without any means of the new price being
                  maintained
                </p>
                <p>
                  <u><b>sharding:</b></u> also known as “fractionalization;” the process of dividing an NFT so it
                  can have multiple owners
                </p>



                <p>
                  <u><b>ser:</b></u> sir
                </p>
                <p>
                  <u><b>shill:</b></u> promote
                </p>
                <p>
                  <u><b>staking:</b></u> the process of storing an NFT or cryptocurrency on a platform where
                  the owner can receive dividends
                </p>


                <p>
                  <u><b>sweep the floor:</b></u> the process of buying all the NFTs in a collection that are listed
                  at the floor price (see above for “floor price”)
                </p>
                <p>
                  <u><b>utility:</b></u> the value an NFT provides (examples: IP rights, access to an event,
                  discounts on future products, meet and greets, etc.)
                </p>
                <p>
                  <u><b>wagmi:</b></u> “we’re all going to make it;” we’re all going to do well
                </p>

                <p>
                  <u><b>wen:</b></u> when
                </p>
                <p>
                  <u><b>whale:</b></u> someone with a lot of money to invest
                </p>




              </Paper>
            </Container>
          </Container>
        </>
      )}
      <Footer />
    </div>

    //     <div>
    //       <Header />

    //       <Container style={{ marginTop: 100 }}>
    //         <Container maxWidth="md" style={{ position: 'relative' }}>
    //           <Paper
    //             style={{
    //               padding: 24,
    //               paddingBottom: 10,
    //               backgroundColor: '#151A1F',
    //               borderRadius: 6,
    //               // textAlign: 'center',
    //               color: '#FFAA00BF',
    //               fontFamily: 'avenir',
    //               lineHeight: 1.43
    //             }}
    //           >

    //             <p><strong><h3><u>NFTs, Crypto, and Blockchains</u></h3></strong></p>

    //               <p>
    //                 When an NFT is purchased, that transaction is recorded on the blockchain. Once
    //                 recorded, this record acts as a verification for who the current owner is of that
    //                 NFT. So, when you purchase and NFT you're actually purchasing the verification
    //                 of ownership for that unique token.
    //               </p>
    //               <br/>



    //             <p><strong><h3><u>What’s the Potential for NFTs?</u></h3></strong></p>
    //             <p>
    //             Tickets and so many more everyday items could become NFTs.
    //               </p>
    //               <br/>

    //             <p><strong><h3><u>NFT/Cryptocurrency Glossary</u></h3></strong></p>



    //             <p>
    //               <u><b>bullish:</b></u> to feel confident about something increasing in price or doing well
    //             </p>
    //             <p>
    //               <u><b>CT:</b></u> Crypto Twitter; an open community of people on Twitter who discuss
    // cryptocurrencies and NFTs
    //             </p>
    //             <p>
    //               <u><b>DAO:</b></u> "decentralized autonomous organization" an organization in which all
    // members have equal say on decisions
    //             </p>
    //             <p>
    //               <u><b>gas fee:</b></u> the tax charged for having a transaction confirmed on the blockchain
    //             </p>
    //             <p>
    //               <u><b>Gm:</b></u> good morning
    //             </p>
    //             <p>
    //               <u><b>gmi:</b></u> “going to make it” going to do well
    //             </p>


    //             <p>
    //               <u><b>HODL</b></u> “hold on for dear life” was originally a typo for “hold” similar meaning to
    // “diamond hands” (see above)
    //             </p>
    //             <p>
    //               <u><b>irl:</b></u> "in real life"
    //             </p>
    //             <p>
    //               <u><b>list:</b></u> the process of putting an NFT on a marketplace for resale
    //             </p>


    //             <p>
    //               <u><b>marketplace</b></u> a platform used for buying a selling NFTs <em>(OpenSea, Magic Eden,
    // Rarible, etc.)</em>
    //             </p>

    //             <p>
    //               <u><b>rug pull:</b></u> an NFT project that “pulls the rug” on its holders; usually disappears
    // after the initial mint sells out, may also leave slowly over time; an NFT project
    // that does not fulfill its promises; an NFT project that artificially inflates the price
    // of their collection with intent to sell without any means of the new price being
    // maintained
    //             </p>
    //             <p>
    //               <u><b>sharding:</b></u> also known as “fractionalization;” the process of dividing an NFT so it
    // can have multiple owners
    //             </p>



    //             <p>
    //               <u><b>ser:</b></u> sir
    //             </p>
    //             <p>
    //               <u><b>shill:</b></u> promote
    //             </p>

    //             <p>
    //               <u><b>wagmi:</b></u> “we’re all going to make it;” we’re all going to do well
    //             </p>

    //             <p>
    //               <u><b>wen:</b></u> when
    //             </p>
    //             <p>
    //               <u><b>whale:</b></u> someone with a lot of money to invest
    //             </p>




    //               {/* <p>
    //                 <strong><h2>What is CheeseComputer?</h2></strong>
    //               </p>

    //               <br/>
    //               <p>
    //                 <h4>Agreement to Terms</h4>
    //                 CheeseComputer (herein referred to as &quot;Cheese&quot; or &quot;we&quot; or &quot;us&quot;) grants the person who mints
    //                 from this website (herein referred to as &quot;you&quot; or &quot;the customer&quot; or &quot;the original customer&quot;)
    //                 commercial rights for the NFT (herein referred to as &quot;the product&quot;) he or she minted from
    //                 cheesecomputer.io (herein referred to as &quot;this website&quot;). If the NFT is sold from the customer to
    //                 another buyer, the original customer relinquishes all commercial rights and those rights are now
    //                 granted to the new buyer.
    //               </p>
    //               <p>
    //                 We will not be liable if for any reason this website is unavailable at any time or for any period.
    //                 From time to time, we may restrict access to some parts or all of this website.
    //                 This website may contain links to other websites (Twitter, MagicEden, etc.), which are not
    //                 operated by us. We have no control over the Linked Sites and accept no responsibility for them
    //                 or for any loss or damage that may arise from your use of them. Your use of the Linked Sites
    //                 will be subject to the terms of use and service contained within each such site.
    //               </p>

    //               <br/>
    //               <p>
    //                 <h4>Prohibitions</h4>
    //                 You must not abuse this website by committing or encouraging a crime including by not limited
    //                 to the following: Transmitting or propagating viruses, Trojan horses, worms, logic bombs, or
    //                 other material that is malicious, technically harmful, unreliable, or in any way offensive or
    //                 obscene; hacking into any aspect of the service to corrupt data, harass other users, violate the
    //                 property rights of others, send unsolicited advertising or promotional materials, commonly
    //                 referred to as &quot;spam&quot; or attempt not to impair the performance or functionality of any computer
    //                 equipment accessed on or through this website. Any violation of this provision is a crime and
    //                 CheeseComputer will report such violations to appropriate law enforcement agencies.
    //               </p>
    //               <p>
    //                 We will not be liable for any loss or damage caused by a distributed denial-of-service attack,
    //                 viruses or other technologically harmful material that may infect your computer equipment,
    //                 computer programs, data or other proprietary material due to your use of this Website or to your
    //                 downloading of any material posted on it, or on any website linked to it.
    //               </p>


    //               <br/>
    //               <p>
    //                 <h4>Terms of Engagement</h4>
    //                 To enter into a contract with CheeseComputer, you must be at least 18 years old and possess a
    // cryptocurrency wallet that we accept. These accepted wallets are shown when you select
    // “Connect” Wallet” on the homepage. CheeseComputer has the right to reject any request you
    // make.
    //               </p>
    //               <p>
    //               When each product is sold for the first time, Cheese Computer and the original purchaser enter
    // into a sales agreement. CheeseComputer is not a party to any agreement between the buyer and
    // seller of NFTs, or between any other users, if the first purchaser decides to sell the NFT. This
    // website serves simply as a platform to enable transactions between a buyer and a seller.
    //               </p>
    //               <p>All products are kept on the Solana blockchain and are accessible through it. Therefore,
    // CheeseComputer does not maintain the products on this website, and other than giving
    // ownership of the products to the first purchaser, CheeseComputer has no influence over the
    // transfer, storage, control, or maintenance of the products.</p>

    // <p>When you link your cryptocurrency wallet to the website through a reputable service you
    // acknowledge and agree to be governed by these Terms and Conditions and all terms incorporated
    // by reference.</p>

    // <p>When placing an order, you warrant that all information provided is accurate and that you are an
    // authorized user of the cryptocurrency wallet. All listed prices are subject to change.</p>

    // <p>You acknowledge that by placing an order on this website, you are submitting a legally binding
    // offer to acquire a product. If you are the first purchaser of a product or if you are acquiring a
    // Service, you must pay any outstanding charges. Payment can be made through: 1.Phantom
    // (https://phantom.app/) , 2. Solflare (https://solflare.com/onboard) , 3. Slop (https://slope.finance/)
    // or 4. Sollet (https://www.sollet.io/). If you are not the first purchaser of a product, monies may
    // be paid to the subsequent holder. You also understand and agree that CheeseComputer earns a
    // percentage of each future sale of a product (the &quot;Royalty&quot;). CheeseComputer has the right to
    // collect Royalties on APC NFT sales in perpetuity and may utilize these monies as it sees fit.</p>


    // <p>Therefore, if you sell products on third-party product marketplaces, you agree to include
    // statements such as the following in your product description:</p>

    // <p>Further, if you purchase or sell products on this site, you agree to pay all associated transaction
    // costs and to allow CheeseComputer to automatically debit and recover such costs from your
    // payment. Always view a breakdown of all transactions and associated costs before purchasing or
    // selling products.
    // All sales are final. Currently, we do not accept product returns, refunds, or exchanges.</p>



    // <br/>
    //               <p>
    //                 <h4>Intellectual Property, Software and Content: IP Rights Granted</h4>
    //                 CheeseComputer or its licensees retain ownership of all intellectual property rights in any
    // software, NFT and information made available to you on or via this website, which are protected
    // by international copyright laws and treaties. CheeseComputer and its licensors reserve all such
    // rights. Upon purchase of the NFTs, you shall have full commercial and personal licenses and are
    // free to use the material for commercial purposes.
    //               </p> */}
    //           </Paper>
    //         </Container>
    //       </Container>
    //       {/* <footer>
    //         <p>
    //           Copyright © 2022, CheeseComputer
    //         </p>
    //       </footer> */}
    //       <Footer />
    //     </div>
  );
};

export default AboutNFT;
