/* eslint-disable jsx-a11y/alt-text */
import { Fragment, useMemo, useState, useEffect, SetStateAction } from 'react';
import './App.css';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

const IntroButton = styled(Button)`
  width: 50%;
  height: 60px;
  // margin-top: 10px;
  margin-bottom: 5px;
  background: linear-gradient(180deg, #FFAA00 0%, #FFAA00 100%);
  color: black;
  font-size: 30px;
  font-weight: bold;
  font-family: avenir;
  border-radius: 0px!important;
  cursor: pointer;
`;


const getWidth = () => window.innerWidth 
|| document.documentElement.clientWidth 
|| document.body.clientWidth;

const getHeight = () => window.innerHeight 
|| document.documentElement.clientHeight 
|| document.body.clientHeight;





const Intro = () => {
  const useCurrentWidth = () => {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());
    let [device, setDevice] = useState('');
    
    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
    
      const width = getWidth();
      updateDevice(width, setDevice);
      
      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
    
        const width = getWidth();
    
        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setWidth(width), 150);
       
        updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);
    
      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])
    
    return [width, device];
  }
    
  const useCurrentHeight = () => {
    // save current window height in the state object
    let [height, setHeight] = useState(getHeight());
    // let [device, setDevice] = useState('');
    
    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {
    
      // const height = getHeight();
      // updateDevice(width, setDevice);
      
      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);
    
        const height = getHeight();
    
        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setHeight(height), 150);
       
        // updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);
    
      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])
    
    return [height, device];
  }

  const updateDevice = (width: number, setDevice: { (value: SetStateAction<string>): void; (arg0: string): void; }): void => {
    const isMobile = width >= 320 && width <= 480;
    const isTablet = width >= 481 && width <= 768;
    const isLaptop = width >= 769 && width <= 1024;
    const isDesktop = width >= 1025 && width <= 1200;
    const isLargeScreen = width >= 1201;
    
    
    if (isMobile) setDevice('mobile');
    if (isTablet) setDevice('tablet');
    if (isLaptop) setDevice('laptop');
    if (isDesktop) setDevice('desktop');
    if (isLargeScreen) setDevice('large screen');
  }

  let [width, device] = useCurrentWidth();
  let [height] = useCurrentHeight();
  return (
    // <div style={{ marginTop: '' }}>
    //   {/* <Container maxWidth="sm" style={{ position: 'relative' }}> */}
    //   <div>
    //     <Paper
    //       style={{
    //         // padding: 24,
    //         paddingBottom: 10,
    //         backgroundColor: '#151A1F',
    //         borderRadius: 6,
    //         textAlign: 'center',
    //         color: '#FFAA00BF'
    //       }}
    //     >
    //       {/* <img src='intro-bg.png' style={{ width: 400, height: 500 }} /> */}
    //       <img 
    //         src='workshop.png' 
    //         style={{
    //           //  width: '100%', 
    //           //  height: 'auto' 
    //         }} 
    //       />
    //       <h1 className='title'>Welcome to CheeseComputer, home of the cheesiest puns.</h1>
    //       <IntroButton href="/home">Enter</IntroButton>
    //     </Paper>
    //   </div>
    // </div>
    <div 
      className="flex-container"
      style={{
        // height: '100%',
        height,
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <div 
        className="row"
          style={{
            textAlign: 'center'
          }}
        // style={{
        //   width: 'auto',
        //   border: '1px solid blue'
        // }}
      > 
          {/* <div className="flex-item"
          > */}
            {device === 'mobile' || device === 'tablet' ? (
                 <img 
                 src='workshop.png' 
                 style={{
                   //  width: '100%', 
                   //  height: 'auto' 
                 }} 
               />
                
            ) : (
              <img 
              src='workshop.jpg' 
              style={{
                 width: '100%', 
                 height: 500 
              }} 
            />
             
            )}

          {/* </div> */}
          
          {/* <p>Device: {device}</p> */}
          <h2 className='title'>Welcome to CheeseComputer! <br/>Join Cheese the mouse on his journey to creating the perfect pun.</h2>
          <IntroButton href="/home" style={{ fontSize:22 }}><strong>Enter</strong></IntroButton>
          {/* <div className="flex-item">2</div>
          <div className="flex-item">3</div>
          <div className="flex-item">4</div> */}
      </div>
  </div>
  );
};

export default Intro;
