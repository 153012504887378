import { Fragment, useMemo, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';


const Header = () => {
  return (
    <nav>
      <a href="/home">Cheese</a>
      <ul
        style={{
          fontFamily: 'avenir',
          fontWeight: 'bold'
        }}
      >
        <li>
          <Link to="/home">Home</Link>
        </li>
        {/* <li>
          <HashLink to="/home#roadmap">Roadmap</HashLink>
        </li>
      */}
        <li>
          <a href="https://discord.cheesecomputer.io" target="_blank" rel="noreferrer">Discord</a>
        </li>
        <li>
          <HashLink to="/stories">Stories</HashLink>
        </li>
        <li>
          <Link to="/about">About CC/Team</Link>
        </li>
        <li>
          <HashLink to="/about-nft">About NFTs</HashLink>
        </li>
        
        <li>
          <a href="https://cheesecomputer.giraffesjourney.io/" target="_blank" rel="noreferrer">Giraffes' Journey</a>
        </li>
        <li>
          <HashLink to="/breeding">Breeding</HashLink>
        </li>



      </ul>
    </nav>
  );
};

export default Header;



