import { Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { AppBar, Container, Divider, Drawer, IconButton, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Header from './Header'
import Footer from './Footer'
import clsx from 'clsx';


import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 140;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'red'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Breeding = () => {

  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  const getHeight = () => window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  const updateDevice = (width: number, setDevice: { (value: SetStateAction<string>): void; (arg0: string): void; }): void => {
    const isMobile = width >= 320 && width <= 480;
    const isTablet = width >= 481 && width <= 768;
    const isLaptop = width >= 769 && width <= 1024;
    const isDesktop = width >= 1025 && width <= 1200;
    const isLargeScreen = width >= 1201;


    if (isMobile) setDevice('mobile');
    if (isTablet) setDevice('tablet');
    if (isLaptop) setDevice('laptop');
    if (isDesktop) setDevice('desktop');
    if (isLargeScreen) setDevice('large screen');
  }

  function useCurrentWidth() {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());





    let [device, setDevice] = useState('');

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {

      const width = getWidth();
      updateDevice(width, setDevice);

      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);

        const width = getWidth();

        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setWidth(width), 150);

        updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])

    return [width, device];
  }

  function useCurrentHeight() {
    // save current window height in the state object
    let [height, setHeight] = useState(getHeight());





    // let [device, setDevice] = useState('');

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {

      // const height = getHeight();
      // updateDevice(width, setDevice);

      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);

        const height = getHeight();

        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setHeight(height), 150);

        // updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])

    return [height, device];
  }


  let [width, device] = useCurrentWidth();
  let [height] = useCurrentHeight();

  return (
    <div>
      {(device === 'mobile') ? (
        <div className={classes.root}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: openDrawer,
            })}
          >
            <Toolbar style={{ background: '#1f1f21' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, openDrawer && classes.hide)}
              >
                <MenuIcon style={{ color: '#FFAA00' }} />
              </IconButton>
              <Typography variant="h6" noWrap style={{ color: '#FFAA00' }}>
                Cheese
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={openDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose} style={{ color: 'white' }}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider style={{ backgroundColor: 'white' }} />
            <List>
              {[
                {
                  name: 'Home',
                  url: '/home'
                },
                {
                  name: 'Stories',
                  url: '/stories'
                },
                {
                  name: 'About CC/Team',
                  url: '/about'
                },
                {
                  name: 'About NFTs',
                  url: '/about-nft'
                },
                {
                  name: 'Breeding',
                  url: '/breeding'
                },
              ].map((page, index) => (
                <ListItem button key={index}>
                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                  {/* <ListItemText primary={text} /> */}
                  <HashLink to={page.url} style={{ color: '#ffaa00' }}>{page.name}</HashLink>
                </ListItem>
              ))}
            </List>
            {/* <Divider />
            <List>
              {['All mail', 'Trash', 'Spam'].map((text, index) => (
                <ListItem button key={text}>
                  <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List> */}
          </Drawer>
          <main
            style={device === 'mobile' ? { padding: 0 } : {}}
            className={clsx(classes.content, {
              [classes.contentShift]: openDrawer,
            })}
          >
            <div className={classes.drawerHeader} />
            <div
              style={device === 'mobile' ? {
                width: '80vw',
                margin: 'auto',
                textAlign: 'center',
                marginLeft: 40,
              } : {
                width: '80vw',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <Paper
                style={{
                  padding: 24,
                  paddingBottom: 10,
                  backgroundColor: '#151A1F',
                  borderRadius: 6,
                  textAlign: 'center',
                  color: '#FFAA00BF'
                }}
              >
                <div className="flex-nav-links">
                  <HashLink to="/stories#backstory">Backstory</HashLink>
                  <HashLink to="/stories#firstjourney">First Journey</HashLink>
                </div>
                <h1>Cheese: A Journey for the Perfect Pun</h1>
                <div id="backstory" className='story'>
                  <h2>Cheese's Backstory</h2>
                  <p>
                    On a small desk in east Oakland, a computer turns off by itself.
                  </p>
                  <img src="/story1.jpg" alt="" />
                  <p>
                    Suddenly, the computer turns back on and a program begins to run. As this program runs, the computer mouse begins to levitate and spin.
                  </p>
                  <img src="story2.jpg" alt="" />
                  <p>
                    After a few seconds, the program continues to run but, the mouse is no longer spinning. The mouse settles back on the desk, no longer as an ordinary computer mouse. It has now transformed into a real mouse wearing glasses, a bow tie, overalls, and sneakers.
                  </p>
                  <img src="story3.jpg" alt="" />
                  <p>
                    After coming to the realization of what’s happened, the mouse begins to think. There is only one thought that comes to the mouse’s mind: “I must create the perfect pun!”
                  </p>
                  <img src="story4.jpg" alt="" />
                  <p>
                    Without wasting a second, the mouse grabs a pencil and paper he finds on the desk and begins to write. The first pun he thinks of begins with the word “Cheese."
                  </p>
                  <img src="story5.jpg" alt="" />
                  <p>
                    Once he finishes writing the word “Cheese,” everything changes. A glowing logo appears on his overalls and there is a bright light coming from behind him.
                  </p>
                  <img src="story6.jpg" alt="" />
                  <p>
                    The computer behind him is being overtaken by a cheese-like substance.
                  </p>
                  <img src="story7.jpg" alt="" />
                  <p>
                    The mouse jumps up to face the computer. At this moment, he sees the cheese-like substance has completely overtaken the computer and keyboard.
                  </p>
                  <img src="story8.jpg" alt="" />
                  <p>
                    He turns back to the paper and writes the rest of the pun. “Cheese the day.” Nothing new happens with the computer. He then decides to write some random words just to see what would happen: “Mouse” “Pencil” “Cup” No changes, the computer is still cheese. Finally, he decides to draw. He flips over the paper and draws a coffee mug. Once he finishes the drawing, he can hear a cracking sound coming from the computer. A coffee mug is emerging from the screen of the cheese computer!
                  </p>
                  <img src="story9.jpg" alt="" />
                  <p>
                    He is shocked by this discovery. He turns back to the paper and draws a mouse-sized pencil, chair, and desk. The small pencil, chair, and desk all emerge from the cheese computer. He now realizes what’s happening. Anything he draws becomes real through the cheese computer. <b>If he can imagine it, he can create it.</b>
                  </p>
                  <p>
                    <i>Join Cheese the computer mouse on his journey to creating the perfect pun. He’ll learn from his surroundings, combine new and old, and most importantly, listen to the people to get it done right.</i>
                  </p>
                  <img src="story10.jpg" alt="" />
                </div>
                <div id="firstjourney" className='story'>
                  <h2>Cheese’s First Journey</h2>
                  <p>
                    Serengeti Safari in Tanzania. He saw many cats lion around, rhinos drinking at the monkey bars, and a group of zebras horsing around.
                    <br /><br />
                    But, it was the tallest creatures there that really caught his attention. Two huge giraffes were fighting. Cheese was amazed by their beauty and roughness. This was it, this would be the inspiration for his perfect pun.
                  </p>
                  <img src="/story11.jpg" alt="" />
                  <p>
                    After watching the creatures for some time, Cheese had his idea. He went back to the cheese computer, plugged in, and began to draw his interpretation of what he witnessed.</p>
                  <img src="story12.png" alt="" />
                  <p>
                    He drew a rough draft of the rough giraffes. This was just the pun he was searching for. He drafted many drafts of the giraffes being careful not to complete the drawings, ensuring they were all left as rough drafts. As he finished his drawings, the creatures began to emerge from the cheese computer.</p>
                  <img src="story13.jpg" alt="" />
                  <p>
                    His perfect pun was here! His life’s mission had been completed! As Cheese marveled at his many rough giraffes, he couldn’t help but wonder: Was this really the perfect pun? Cheese looked up to the sky wondering if that was it...</p>
                </div>
              </Paper>
            </div>
          </main>
        </div>
      ) : (
        <>
          <Header />
          <Container style={{ marginTop: 100 }}>
            <Container maxWidth="md" style={{ position: 'relative' }}>
              <Paper
                style={{
                  padding: 24,
                  paddingBottom: 10,
                  backgroundColor: '#151A1F',
                  borderRadius: 6,
                  textAlign: 'center',
                  color: '#FFAA00BF'
                }}
              >
                <div className="flex-nav-links">
                  <HashLink to="/stories#backstory">Backstory</HashLink>
                  <HashLink to="/stories#firstjourney">First Journey</HashLink>
                </div>
                <h1>Cheese: A Journey for the Perfect Pun</h1>
                <div id="backstory" className='story'>
                  <h2>Cheese's Backstory</h2>
                  <p>
                    On a small desk in east Oakland, a computer turns off by itself.
                  </p>
                  <img src="/story1.jpg" alt="" />
                  <p>
                    Suddenly, the computer turns back on and a program begins to run. As this program runs, the computer mouse begins to levitate and spin.
                  </p>
                  <img src="story2.jpg" alt="" />
                  <p>
                    After a few seconds, the program continues to run but, the mouse is no longer spinning. The mouse settles back on the desk, no longer as an ordinary computer mouse. It has now transformed into a real mouse wearing glasses, a bow tie, overalls, and sneakers.
                  </p>
                  <img src="story3.jpg" alt="" />
                  <p>
                    After coming to the realization of what’s happened, the mouse begins to think. There is only one thought that comes to the mouse’s mind: “I must create the perfect pun!”
                  </p>
                  <img src="story4.jpg" alt="" />
                  <p>
                    Without wasting a second, the mouse grabs a pencil and paper he finds on the desk and begins to write. The first pun he thinks of begins with the word “Cheese."
                  </p>
                  <img src="story5.jpg" alt="" />
                  <p>
                    Once he finishes writing the word “Cheese,” everything changes. A glowing logo appears on his overalls and there is a bright light coming from behind him.
                  </p>
                  <img src="story6.jpg" alt="" />
                  <p>
                    The computer behind him is being overtaken by a cheese-like substance.
                  </p>
                  <img src="story7.jpg" alt="" />
                  <p>
                    The mouse jumps up to face the computer. At this moment, he sees the cheese-like substance has completely overtaken the computer and keyboard.
                  </p>
                  <img src="story8.jpg" alt="" />
                  <p>
                    He turns back to the paper and writes the rest of the pun. “Cheese the day.” Nothing new happens with the computer. He then decides to write some random words just to see what would happen: “Mouse” “Pencil” “Cup” No changes, the computer is still cheese. Finally, he decides to draw. He flips over the paper and draws a coffee mug. Once he finishes the drawing, he can hear a cracking sound coming from the computer. A coffee mug is emerging from the screen of the cheese computer!
                  </p>
                  <img src="story9.jpg" alt="" />
                  <p>
                    He is shocked by this discovery. He turns back to the paper and draws a mouse-sized pencil, chair, and desk. The small pencil, chair, and desk all emerge from the cheese computer. He now realizes what’s happening. Anything he draws becomes real through the cheese computer. <b>If he can imagine it, he can create it.</b>
                  </p>
                  <p>
                    <i>Join Cheese the computer mouse on his journey to creating the perfect pun. He’ll learn from his surroundings, combine new and old, and most importantly, listen to the people to get it done right.</i>
                  </p>
                  <img src="story10.jpg" alt="" />
                </div>
                <div id="firstjourney" className='story'>
                  <h2>Cheese’s First Journey</h2>
                  <p>
                    Serengeti Safari in Tanzania. He saw many cats lion around, rhinos drinking at the monkey bars, and a group of zebras horsing around.
                    <br /><br />
                    But, it was the tallest creatures there that really caught his attention. Two huge giraffes were fighting. Cheese was amazed by their beauty and roughness. This was it, this would be the inspiration for his perfect pun.
                  </p>
                  <img src="/story11.jpg" alt="" />
                  <p>
                    After watching the creatures for some time, Cheese had his idea. He went back to the cheese computer, plugged in, and began to draw his interpretation of what he witnessed.</p>
                  <img src="story12.png" alt="" />
                  <p>
                    He drew a rough draft of the rough giraffes. This was just the pun he was searching for. He drafted many drafts of the giraffes being careful not to complete the drawings, ensuring they were all left as rough drafts. As he finished his drawings, the creatures began to emerge from the cheese computer.</p>
                  <img src="story13.jpg" alt="" />
                  <p>
                    His perfect pun was here! His life’s mission had been completed! As Cheese marveled at his many rough giraffes, he couldn’t help but wonder: Was this really the perfect pun? Cheese looked up to the sky wondering if that was it...</p>
                </div>


              </Paper>
            </Container>
          </Container>
        </>
      )}
      <Footer />
    </div>
  );
};

export default Breeding;
