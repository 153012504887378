import './App.css';
import { Fragment, useMemo, useState, useEffect, SetStateAction } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import * as anchor from '@project-serum/anchor';
import Home from './Home';
import { DEFAULT_TIMEOUT } from './connection';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from '@solana/wallet-adapter-wallets';
import ImageMapper from "react-img-mapper";
import areas from "./areas.json";
import Footer from './Footer';
import Header from './Header';
// import Menu from '@material-ui/core/Menu';
// import MenuItem from '@material-ui/core/MenuItem';

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { createTheme, ThemeProvider } from '@material-ui/core';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';

// const drawerWidth = 240;
const drawerWidth = 140;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'red'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));


// const theme = createTheme({
//   palette: {
//     type: 'dark',
//   },
// });

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const candyMachineId = getCandyMachineId();
const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  'devnet') as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl('devnet');
const connection = new anchor.web3.Connection(rpcHost);

const Main = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [popupStyle, setPopupStyle] = useState({});
  const closeModal = () => setOpen(false);

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      // getSolflareWallet(),
      // getSlopeWallet(),
      // getSolletWallet({ network }),
      // getSolletExtensionWallet({ network }),
    ],
    [],
  );

  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  const getHeight = () => window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  const updateDevice = (width: number, setDevice: { (value: SetStateAction<string>): void; (arg0: string): void; }): void => {
    const isMobile = width >= 320 && width <= 480;
    const isTablet = width >= 481 && width <= 768;
    const isLaptop = width >= 769 && width <= 1024;
    const isDesktop = width >= 1025 && width <= 1200;
    const isLargeScreen = width >= 1201;


    if (isMobile) setDevice('mobile');
    if (isTablet) setDevice('tablet');
    if (isLaptop) setDevice('laptop');
    if (isDesktop) setDevice('desktop');
    if (isLargeScreen) setDevice('large screen');
  }

  function useCurrentWidth() {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());





    let [device, setDevice] = useState('');

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {

      const width = getWidth();
      updateDevice(width, setDevice);

      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);

        const width = getWidth();

        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setWidth(width), 150);

        updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])

    return [width, device];
  }

  function useCurrentHeight() {
    // save current window height in the state object
    let [height, setHeight] = useState(getHeight());





    // let [device, setDevice] = useState('');

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {

      // const height = getHeight();
      // updateDevice(width, setDevice);

      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);

        const height = getHeight();

        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setHeight(height), 150);

        // updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])

    return [height, device];
  }


  let [width, device] = useCurrentWidth();
  let [height] = useCurrentHeight();

  // const URL = "phases.png";
  const URL = "phases2.png";
  const MAP = {
    name: "my-map",
    areas
  };
  return (
    <>
      {(device === 'mobile') && (
        <div className={classes.root}>
          {/* <CssBaseline /> */}
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: openDrawer,
            })}
          >
            <Toolbar style={{ background: '#1f1f21' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, openDrawer && classes.hide)}
              >
                <MenuIcon style={{ color: '#FFAA00' }} />
              </IconButton>
              <Typography variant="h6" noWrap style={{ color: '#FFAA00' }}>
                Cheese
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={openDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose} style={{ color: 'white' }}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider style={{ backgroundColor: 'white' }} />
            <List>
              {[
                {
                  name: 'Home',
                  url: '/home'
                },
                {
                  name: 'Stories',
                  url: '/stories'
                },
                {
                  name: 'About CC/Team',
                  url: '/about'
                },
                {
                  name: 'About NFTs',
                  url: '/about-nft'
                },
                {
                  name: 'Breeding',
                  url: '/breeding'
                },
              ].map((page, index) => (
                <ListItem button key={index}>
                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                  {/* <ListItemText primary={text} /> */}
                  <HashLink to={page.url} style={{ color: '#ffaa00' }}>{page.name}</HashLink>
                </ListItem>
              ))}
            </List>
            {/* <Divider />
            <List>
              {['All mail', 'Trash', 'Spam'].map((text, index) => (
                <ListItem button key={text}>
                  <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List> */}
          </Drawer>
          <main
            style={device === 'mobile' ? { padding: 0 } : {}}
            className={clsx(classes.content, {
              [classes.contentShift]: openDrawer,
            })}
          >
            <div className={classes.drawerHeader} />
            <div
              style={device === 'mobile' ? {
                width: '80vw',
                margin: 'auto',
                textAlign: 'center',
                marginLeft: 40,
              } : {
                width: '80vw',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <div className="right">
                <img style={device === 'mobile' ? { width: '44vw' } : {}} src="mouse.png" alt="" />
              </div>
              <div className="">
                {device === 'mobile' ? <h2 className='title'>Phase 2: The Rough Giraffes</h2> : <h1 className='title'>Phase 2: The Rough Giraffes</h1>}
                <p className='desc'>
                  {/* 
                  Welcome to the first collection of the <HashLink style={{ color: 'inherit' }} to="/home#img-mapper">Sliced Cheese</HashLink> phase. 
                  These designs have been worked on for years but still aren't done yet. 
                  We present to you, 2,888 Rough Drafts. */}


                  Welcome to the first collection of the
                  {/* <HashLink style={{ color: 'inherit' }} to="/home#img-mapper">Sliced Cheese</HashLink> phase.  */}
                  <Popup
                    trigger={<span style={{ textDecoration: 'underline' }}> Sliced Cheese</span>}
                    position="center center"
                    contentStyle={{ width: 300 }}
                  >
                    <img src="phase-2.png" style={{
                      width: 300
                    }} />
                  </Popup>
                  &nbsp;phase.
                  After a trip to the safari, Cheese drew inspiration for his perfect pun from the tallest creatures there. <br /><br />

                  We present to you, 2,888 Rough Drafts. This is a free mint with 30 NFTs reserved for team and giveaways.
                  <br /><br />
                  As Cheese marveled at his creation, he wondered if this is really the perfect pun...
                </p>
                <img src='site-rd.gif' style={{ width: 250, height: 250 }} />
                {/* <ThemeProvider theme={theme}> */}

                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>
                      <Home
                        candyMachineId={candyMachineId}
                        connection={connection}
                        txTimeout={DEFAULT_TIMEOUT}
                        rpcHost={rpcHost}
                        network={network}
                        error={error}
                        device={device}
                      />
                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
                {/* </ThemeProvider> */}
              </div>

            </div>
            <h1 className="title" style={{ textAlign: 'center' }}>Cheese: A Journey for the Perfect Pun</h1>
            <div className='backstory'>

              <div>
                <img src="backstory.jpg" alt="" />
                <HashLink to="/stories#backstory">Backstory</HashLink>
              </div>
              <div>
                <img src="firstjourney.jpg" alt="" />
                <HashLink to="/stories#backstory">First Journey</HashLink>
              </div>

            </div>
            {/* <h2 className="title" style={{ textAlign: 'center', width }}>Next 3 Collections Sneak Peak</h2> */}
            {/* <h2 style={{ textAlign: 'center' }}>Next 3 Collections Sneak Peak</h2> */}

            {/* <div className="flexcards"> */}
            <h1 style={{ textAlign: 'center' }}>Next 3 Collections Sneak Peak</h1>
            <div className="">
              <div className="card-sm">
                <h2>2nd Collection Hint</h2>
                <p style={{ fontSize: 18, fontFamily: 'avenir' }}>
                  Listen, we do strutural, mechanical, environmental, electrical, software, and many more. Do you hear me?
                </p>
              </div>
              <div className="card-sm">
                <h2>3rd Collection Hint</h2>
                <p style={{ fontSize: 18, fontFamily: 'avenir' }}>
                  {'>>>'} weather = "warm"<br />
                  {'>>>'} if weather == "cold":<br />
                  ...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print("bring jacket")<br />
                  ...&nbsp;&nbsp;&nbsp;else:<br />
                  ...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print("bring jacket anyways")<br />
                </p>
              </div>
              <div className="card-sm">
                <h2>4th Collection Hint</h2>
                <p style={{ fontSize: 18, fontFamily: 'avenir' }}>
                  Okay bro it's safe to swim. Also, where's the nearest toilet?
                </p>
              </div>
            </div>
            <div id="roadmap" className="roadmap" style={{ width: 400 }}>
              <h1>Project Overview</h1>
              <div className="img-wrapper"
                style={{
                  position: 'relative',
                  width: 400,
                }}
              >
                <img className="img-responsive"
                  style={{
                    height: 400,
                    width: 400,
                  }}
                  src="phases2.png" />

                <div className="img-overlay"
                  id="one"
                  style={{
                    position: 'absolute',
                    top: '12%',
                    bottom: 0,
                    left: 0,
                    textAlign: 'center'
                  }}
                >
                  <button className="btn btn-md btn-success"
                    style={{
                      background: `url(sliced-button.png) no-repeat`,
                      backgroundSize: 75,
                      width: 75,
                      height: 50,
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onClick={(event) => {
                      console.log(`${event.clientX}, ${event.clientY}`)
                      setMessage('2');
                      const style: Record<string, any> = {};
                      style.top = `${event.clientY}px`;
                      style.position = 'absolute';

                      style.left = `${event.clientX}px`;
                      setPopupStyle(style);
                      setOpen(true);

                    }}
                  ></button>
                </div>

                <div className="img-overlay"
                  id="two"
                  style={{
                    position: 'absolute',
                    top: '58%',
                    bottom: 0,
                    left: 0,
                    textAlign: 'right'
                  }}
                >
                  <button className="btn btn-md btn-success"
                    style={{
                      background: `url(shredded-button.png) no-repeat`,
                      backgroundSize: 75,
                      width: 75,
                      height: 50,
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onClick={(event) => {
                      console.log(`${event.clientX}, ${event.clientY}`)
                      setMessage('1');
                      const style: Record<string, any> = {};
                      style.top = `${event.clientY}px`;
                      style.position = 'absolute';

                      style.left = `${event.clientX}px`;

                      setPopupStyle(style);
                      setOpen(true);
                    }}
                  ></button>
                </div>

                <div className="img-overlay"
                  id="three"
                  style={{
                    position: 'absolute',
                    top: '59%',
                    bottom: 0,
                    left: 0,
                    textAlign: 'left'
                  }}
                >
                  <button className="btn btn-md btn-success"
                    style={{
                      background: `url(blocked-button.png) no-repeat`,
                      backgroundSize: 75,
                      width: 75,
                      height: 50,
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onClick={(event) => {
                      console.log(`${event.clientX}, ${event.clientY}`)
                      setMessage('3');
                      const style: Record<string, any> = {};
                      style.top = `${event.clientY}px`;
                      style.position = 'absolute';

                      style.left = `${event.clientX}px`;

                      setPopupStyle(style);
                      setOpen(true);
                    }}
                  ></button>
                </div>

                {/* <div className="img-overlay"
                  id="one"
                  style={{
                    position: 'absolute',
                    top: '12%',
                    bottom: 0,
                    left: 0,
                    textAlign: 'center'
                  }}
                >
                  <button className="btn btn-md btn-success"
                    style={{
                      background: `url(sliced-button.png) no-repeat`,
                      backgroundSize: 75,
                      width: 75,
                      height: 50,
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onClick={(event) => {
                      console.log(`${event.clientX}, ${event.clientY}`)
                      setMessage('2');
                      const style: Record<string, any> = {};
                      style.top = `${event.clientY}px`;
                      style.position = 'absolute';

                      style.left = `${event.clientX}px`;
                      setPopupStyle(style);
                      setOpen(true);

                    }}
                  ></button>
                </div>

                <div className="img-overlay"
                  id="two"
                  style={{
                    position: 'absolute',
                    top: '58%',
                    bottom: 0,
                    left: 0,
                    textAlign: 'right'
                  }}
                >
                  <button className="btn btn-md btn-success"
                    style={{
                      background: `url(shredded-button.png) no-repeat`,
                      backgroundSize: 75,
                      width: 75,
                      height: 50,
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onClick={(event) => {
                      console.log(`${event.clientX}, ${event.clientY}`)
                      setMessage('1');
                      const style: Record<string, any> = {};
                      style.top = `${event.clientY}px`;
                      style.position = 'absolute';

                      style.left = `${event.clientX}px`;

                      setPopupStyle(style);
                      setOpen(true);
                    }}
                  ></button>
                </div>

                <div className="img-overlay"
                  id="three"
                  style={{
                    position: 'absolute',
                    top: '59%',
                    bottom: 0,
                    left: 0,
                    textAlign: 'left'
                  }}
                >
                  <button className="btn btn-md btn-success"
                    style={{
                      background: `url(blocked-button.png) no-repeat`,
                      backgroundSize: 75,
                      width: 75,
                      height: 50,
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onClick={(event) => {
                      console.log(`${event.clientX}, ${event.clientY}`)
                      setMessage('3');
                      const style: Record<string, any> = {};
                      style.top = `${event.clientY}px`;
                      style.position = 'absolute';

                      style.left = `${event.clientX}px`;

                      setPopupStyle(style);
                      setOpen(true);
                    }}
                  ></button>
                </div> */}
              </div>

              <Popup
                open={open}
                closeOnDocumentClick
                onClose={closeModal}
                contentStyle={{
                  width: "300px",
                  height: "330px",
                  left: 60
                  // ...popupStyle 
                }}>
                <div className="modal">
                  <a className="close" onClick={closeModal} style={{ cursor: 'pointer' }}>Close
                  </a>
                  <img src={`phase-${message}.png`} alt="" style={{ width: "300px", height: "300px" }} />
                </div>
              </Popup>
            </div>
            <div className="thankyou" style={{ display: 'block', textAlign: 'center', width: '92vw', margin: '0 20px' }}>

              <div className="text">
                <h2>Thank you for Coming</h2>
                <p>
                  Whether you came for a mint, or already had your teeth brushed, we appreciate your visit. We wouldn't be here if it weren't for you. Community isn't everything, community is the only thing. Hope to see you again soon.
                </p>
              </div>
              <img src="comp.png" alt="" />
            </div>
            <Footer />
          </main>
        </div>
      )}


      {(device === 'tablet' || device === 'laptop' || device === 'desktop' || device === 'large screen') && (
        <div style={device === 'tablet' || (device === 'laptop' && height <= 430) ? {
          textAlign: 'center'
        } : {}}>
          <Header />


          {device === 'tablet' || (device === 'laptop' && height <= 430) ? (
            <>
              <div style={{ padding: '0 5em', marginBottom: '1em' }}>
                <h1 className=''>Phase 2: The Rough Giraffes</h1>
                <p className='desc' style={{ textAlign: 'center' }}>

                  {/* 
                 Welcome to the first collection of the <HashLink style={{ color: 'inherit' }} to="/home#img-mapper">Sliced Cheese</HashLink> phase. 
                  These designs have been worked on for years but still aren't done yet. 
                  We present to you, 2,888 Rough Drafts. */}

                  Welcome to the first collection of the
                  {/* <HashLink style={{ color: 'inherit' }} to="/home#img-mapper">Sliced Cheese</HashLink> phase.  */}
                  <Popup
                    trigger={<span style={{ textDecoration: 'underline' }}> Sliced Cheese</span>}
                    position="left center"
                    contentStyle={{ width: 400 }}
                  >
                    <img src="phase-2.png" style={{
                      width: 400
                    }} />
                  </Popup>
                  &nbsp;phase.
                  After a trip to the safari, Cheese drew inspiration for his perfect pun from the tallest creatures there. <br /><br />
                  We present to you, 2,888 Rough Drafts. This is a free mint with 30 NFTs reserved for team and giveaways. <br /> <br />
                  As Cheese marveled at his creation, he wondered if this is really the perfect pun. . .
                </p>



              </div>
              <div className="firstsec">
                <div className="left">
                  <img src='site-rd.gif' style={{ width: 250, height: 250 }} />
                  <ThemeProvider theme={theme}>
                    <ConnectionProvider endpoint={endpoint}>
                      <WalletProvider wallets={wallets} autoConnect>
                        <WalletDialogProvider>
                          <Home
                            candyMachineId={candyMachineId}
                            connection={connection}
                            txTimeout={DEFAULT_TIMEOUT}
                            rpcHost={rpcHost}
                            network={network}
                            error={error}
                            device={device}
                          />
                        </WalletDialogProvider>
                      </WalletProvider>
                    </ConnectionProvider>
                  </ThemeProvider>
                </div>
                <div className="right">
                  <img src="mouse.png" alt="" />
                </div>
              </div>
            </>
          ) : (
            <div className="firstsec">
              <div className="left">
                <h1 className='title'>Phase 2: The Rough Giraffes</h1>
                <p className='desc'>
                  Welcome to the first collection of the
                  {/* <HashLink style={{ color: 'inherit' }} to="/home#img-mapper">Sliced Cheese</HashLink> phase.  */}
                  <Popup
                    trigger={<span style={{ textDecoration: 'underline' }}> Sliced Cheese</span>}
                    position="right center"
                    contentStyle={{ width: 400 }}
                  >
                    <img src="phase-2.png" style={{
                      width: 400
                    }} />
                  </Popup>
                  &nbsp;phase.
                  After a trip to the safari, Cheese drew inspiration for his perfect pun from the tallest creatures there.
                  <br /><br />
                  We present to you, 2,888 Rough Drafts. This is a free mint with 30 NFTs reserved for team and giveaways.
                  <br /> <br />
                  As Cheese marveled at his creation, he wondered if this is really the perfect pun. . .
                </p>
                <img src='site-rd.gif' style={{ width: 250, height: 250 }} />
                <ThemeProvider theme={theme}>
                  <ConnectionProvider endpoint={endpoint}>
                    <WalletProvider wallets={wallets} autoConnect>
                      <WalletDialogProvider>
                        <Home
                          candyMachineId={candyMachineId}
                          connection={connection}
                          txTimeout={DEFAULT_TIMEOUT}
                          rpcHost={rpcHost}
                          network={network}
                          error={error}
                          device={device}
                        />
                      </WalletDialogProvider>
                    </WalletProvider>
                  </ConnectionProvider>
                </ThemeProvider>
              </div>
              <div className="right">
                <img src="mouse.png" alt="" />
              </div>
            </div>
          )}
          <h1 className="title" style={{ fontSize: '56px', textAlign: 'center' }}>Cheese: A Journey for the Perfect Pun</h1>
          <div className='backstory'>

            <div>
              <img src="backstory.jpg" alt="" />
              <HashLink to="/stories#backstory">Backstory</HashLink>
            </div>
            <div>
              <img src="firstjourney.jpg" alt="" />
              <HashLink to="/stories#firstjourney">First Journey</HashLink>
            </div>

          </div>
          <div>
            <h1 className="title" style={{ fontSize: '56px', textAlign: 'center' }}>Next 3 Collections Sneak Peak</h1>
            <div className="flexcards">

              <div className="card">
                <h2>2nd Collection Hint</h2>
                <p style={{ fontSize: 18, fontFamily: 'avenir' }}>
                  Listen, we do strutural, mechanical, environmental, electrical, software, and many more. Do you hear me?
                </p>
              </div>
              <div className="card">
                <h2>3rd Collection Hint</h2>
                <p style={{ fontSize: 18, fontFamily: 'avenir' }}>
                  {'>>>'} weather = "warm"<br />
                  {'>>>'} if weather == "cold":<br />
                  ...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print("bring jacket")<br />
                  ...&nbsp;&nbsp;&nbsp;else:<br />
                  ...&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;print("bring jacket anyways")<br />
                </p>
              </div>
              <div className="card">
                <h2>4th Collection Hint</h2>
                <p style={{ fontSize: 18, fontFamily: 'avenir' }}>
                  Okay bro it's safe to swim. Also, where's the nearest toilet?
                </p>
              </div>
            </div>
            <div id="roadmap" className="roadmap">
              <h1>Project Overview</h1>
              <div className="img-wrapper"
                style={{
                  position: 'relative'
                }}
              >
                <img className="img-responsive"
                  style={{
                    height: 500,
                    width: 500,
                  }}
                  src="phases2.png" />

                <div className="img-overlay"
                  id="one"
                  style={{
                    position: 'absolute',
                    top: '12%',
                    bottom: 0,
                    left: 0,
                    textAlign: 'center'
                  }}
                >
                  <button className="btn btn-md btn-success"
                    style={{
                      background: `url(sliced-button.png) no-repeat`,
                      backgroundSize: 75,
                      width: 75,
                      height: 50,
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onClick={(event) => {
                      console.log(`${event.clientX}, ${event.clientY}`)
                      setMessage('2');
                      const style: Record<string, any> = {};
                      style.top = `${event.clientY}px`;
                      style.position = 'absolute';

                      style.left = `${event.clientX}px`;
                      setPopupStyle(style);
                      setOpen(true);

                    }}
                  ></button>
                </div>

                <div className="img-overlay"
                  id="two"
                  style={{
                    position: 'absolute',
                    top: '58%',
                    bottom: 0,
                    left: 0,
                    textAlign: 'right'
                  }}
                >
                  <button className="btn btn-md btn-success"
                    style={{
                      background: `url(shredded-button.png) no-repeat`,
                      backgroundSize: 75,
                      width: 75,
                      height: 50,
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onClick={(event) => {
                      console.log(`${event.clientX}, ${event.clientY}`)
                      setMessage('1');
                      const style: Record<string, any> = {};
                      style.top = `${event.clientY}px`;
                      style.position = 'absolute';

                      style.left = `${event.clientX}px`;

                      setPopupStyle(style);
                      setOpen(true);
                    }}
                  ></button>
                </div>

                <div className="img-overlay"
                  id="three"
                  style={{
                    position: 'absolute',
                    top: '59%',
                    bottom: 0,
                    left: 0,
                    textAlign: 'left'
                  }}
                >
                  <button className="btn btn-md btn-success"
                    style={{
                      background: `url(blocked-button.png) no-repeat`,
                      backgroundSize: 75,
                      width: 75,
                      height: 50,
                      cursor: 'pointer',
                      border: 'none',
                    }}
                    onClick={(event) => {
                      console.log(`${event.clientX}, ${event.clientY}`)
                      setMessage('3');
                      const style: Record<string, any> = {};
                      style.top = `${event.clientY}px`;
                      style.position = 'absolute';

                      style.left = `${event.clientX}px`;

                      setPopupStyle(style);
                      setOpen(true);
                    }}
                  ></button>
                </div>
              </div>

              <Popup open={open} closeOnDocumentClick onClose={closeModal} contentStyle={{
                width: "400px",
                height: "430px",
                // ...popupStyle 
              }}>
                <div className="modal">
                  <a className="close" onClick={closeModal} style={{ cursor: 'pointer' }}>Close
                  </a>
                  <img src={`phase-${message}.png`} alt="" style={{ width: "400px", height: "400px" }} />
                </div>
              </Popup>
            </div>
            {(device === 'laptop' && height <= 430) ? (
              <div className="thankyou" style={{ display: 'block', textAlign: 'center', width: '92vw', margin: '0 20px' }}>

                <div className="text">
                  <h2>Thank you for Coming</h2>
                  <p>
                    Whether you came for a mint, or already had your teeth brushed, we appreciate your visit. We wouldn't be here if it weren't for you. Community isn't everything, community is the only thing. Hope to see you again soon.
                  </p>
                </div>
                <img src="comp.png" alt="" />
              </div>
            ) : (
              <div className="thankyou">
                <img src="comp.png" alt="" />
                <div className="text">
                  <h2>Thank you for Coming</h2>
                  <p>
                    Whether you came for a mint, or already had your teeth brushed, we appreciate your visit. We wouldn't be here if it weren't for you. Community isn't everything, community is the only thing. Hope to see you again soon.
                  </p>
                </div>
              </div>
            )}
            <Footer />
          </div>
        </div>
      )}
    </>
  );
};

export default Main;
