import './App.css';
import { useMemo, useState } from 'react';
// import {
//   BrowserRouter as Router,
//   Routes,
//   Route,
//   Link
// } from "react-router-dom";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import { HashLink } from 'react-router-hash-link';
import * as anchor from '@project-serum/anchor';
import Intro from './Intro';
import Main from './Main';
import About from './About';
import TermsAndConditions from './TermsAndConditions';
import { DEFAULT_TIMEOUT } from './connection';
import { clusterApiUrl } from '@solana/web3.js';
import { WalletAdapterNetwork } from '@solana/wallet-adapter-base';
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from '@solana/wallet-adapter-wallets';
import ImageMapper from "react-img-mapper";
import areas from "./areas.json";

import {
  ConnectionProvider,
  WalletProvider,
} from '@solana/wallet-adapter-react';
import { WalletDialogProvider } from '@solana/wallet-adapter-material-ui';

import { createTheme, ThemeProvider } from '@material-ui/core';

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import Breeding from './Breeding';
import AboutNFT from './AboutNFT';
import Stories from './Stories';
const theme = createTheme({
  palette: {
    type: 'dark',
  },
});

const getCandyMachineId = (): anchor.web3.PublicKey | undefined => {
  try {
    return new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID!);
  } catch (e) {
    console.log('Failed to construct CandyMachineId', e);
    return undefined;
  }
};

let error: string | undefined = undefined;

if (process.env.REACT_APP_SOLANA_NETWORK === undefined) {
  error =
    "Your REACT_APP_SOLANA_NETWORK value in the .env file doesn't look right! The options are devnet and mainnet-beta!";
} else if (process.env.REACT_APP_SOLANA_RPC_HOST === undefined) {
  error =
    "Your REACT_APP_SOLANA_RPC_HOST value in the .env file doesn't look right! Make sure you enter it in as a plain-text url (i.e., https://metaplex.devnet.rpcpool.com/)";
}

const candyMachineId = getCandyMachineId();
const network = (process.env.REACT_APP_SOLANA_NETWORK ??
  'devnet') as WalletAdapterNetwork;
const rpcHost =
  process.env.REACT_APP_SOLANA_RPC_HOST ?? anchor.web3.clusterApiUrl('devnet');
const connection = new anchor.web3.Connection(rpcHost);


const App = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [popupStyle, setPopupStyle] = useState({});
  const closeModal = () => setOpen(false);

  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      // getSolflareWallet(),
      // getSlopeWallet(),
      // getSolletWallet({ network }),
      // getSolletExtensionWallet({ network }),
    ],
    [],
  );

  const URL = "phases.png";
  const MAP = {
    name: "my-map",
    areas
  };

  

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Intro />
          </Route>
          <Route exact path="/home">
            <Main />
          </Route>
          <Route path="/breeding">
            <Breeding />
          </Route>
          <Route path="/about-nft">
            <AboutNFT />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/stories">
            <Stories />
          </Route>

          <Route path="/terms">
            <TermsAndConditions />
          </Route>
          


          <ul>
          <li>
            <Link to="/home">Home</Link>
          </li>
          <li>
            <HashLink to="/home#roadmap">Roadmap</HashLink>
          </li>
          <li>
            <HashLink to="/breeding">Breeding</HashLink>
          </li>
          <li>
            <a href="https://course.cheesecomputer.io/">RG - Course</a>
          </li>
          <li>
            <HashLink to="/about-nft">About NFTs</HashLink>
          </li>
          <li>
            <Link to="/about">About Us</Link>
          </li>
        </ul>
        </Switch>

      </div>
    </Router>
  );
};

export default App;
