import { Fragment, SetStateAction, useEffect, useMemo, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { AppBar, Container, Divider, Drawer, IconButton, List, ListItem, Toolbar, Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Header from './Header'
import Footer from './Footer'
import clsx from 'clsx';


import { makeStyles, useTheme } from '@material-ui/core/styles';

const drawerWidth = 140;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    textAlign: 'center'
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    background: 'red'
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const About = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);

  const handleDrawerOpen = () => {
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  const getWidth = () => window.innerWidth
    || document.documentElement.clientWidth
    || document.body.clientWidth;

  const getHeight = () => window.innerHeight
    || document.documentElement.clientHeight
    || document.body.clientHeight;

  const updateDevice = (width: number, setDevice: { (value: SetStateAction<string>): void; (arg0: string): void; }): void => {
    const isMobile = width >= 320 && width <= 480;
    const isTablet = width >= 481 && width <= 768;
    const isLaptop = width >= 769 && width <= 1024;
    const isDesktop = width >= 1025 && width <= 1200;
    const isLargeScreen = width >= 1201;


    if (isMobile) setDevice('mobile');
    if (isTablet) setDevice('tablet');
    if (isLaptop) setDevice('laptop');
    if (isDesktop) setDevice('desktop');
    if (isLargeScreen) setDevice('large screen');
  }

  function useCurrentWidth() {
    // save current window width in the state object
    let [width, setWidth] = useState(getWidth());





    let [device, setDevice] = useState('');

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {

      const width = getWidth();
      updateDevice(width, setDevice);

      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);

        const width = getWidth();

        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setWidth(width), 150);

        updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])

    return [width, device];
  }

  function useCurrentHeight() {
    // save current window height in the state object
    let [height, setHeight] = useState(getHeight());





    // let [device, setDevice] = useState('');

    // in this case useEffect will execute only once because
    // it does not have any dependencies.
    useEffect(() => {

      // const height = getHeight();
      // updateDevice(width, setDevice);

      // timeoutId for debounce mechanism
      let timeoutId: NodeJS.Timeout;
      const resizeListener = () => {
        // prevent execution of previous setTimeout
        clearTimeout(timeoutId);

        const height = getHeight();

        // change width from the state object after 150 milliseconds
        timeoutId = setTimeout(() => setHeight(height), 150);

        // updateDevice(width, setDevice);
      };
      // set resize listener
      window.addEventListener('resize', resizeListener);

      // clean up function
      return () => {
        // remove resize listener
        window.removeEventListener('resize', resizeListener);
      }
    }, [])

    return [height, device];
  }


  let [width, device] = useCurrentWidth();
  let [height] = useCurrentHeight();
  return (

    <div>
      {(device === 'mobile') ? (
        <div className={classes.root}>
          <AppBar
            position="fixed"
            className={clsx(classes.appBar, {
              [classes.appBarShift]: openDrawer,
            })}
          >
            <Toolbar style={{ background: '#1f1f21' }}>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
                className={clsx(classes.menuButton, openDrawer && classes.hide)}
              >
                <MenuIcon style={{ color: '#FFAA00' }} />
              </IconButton>
              <Typography variant="h6" noWrap style={{ color: '#FFAA00' }}>
                Cheese
              </Typography>
            </Toolbar>
          </AppBar>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={openDrawer}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose} style={{ color: 'white' }}>
                {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
            </div>
            <Divider style={{ backgroundColor: 'white' }} />
            <List>
              {[
                 {
                  name: 'Home',
                  url: '/home'
                },
                {
                  name: 'Stories',
                  url: '/stories'
                },
                {
                  name: 'About CC/Team',
                  url: '/about'
                },
                {
                  name: 'About NFTs',
                  url: '/about-nft'
                },
                {
                  name: 'Breeding',
                  url: '/breeding'
                },
              ].map((page, index) => (
                <ListItem button key={index}>
                  {/* <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon> */}
                  {/* <ListItemText primary={text} /> */}
                  <HashLink to={page.url} style={{ color: '#ffaa00' }}>{page.name}</HashLink>
                </ListItem>
              ))}
            </List>
            {/* <Divider />
            <List>
              {['All mail', 'Trash', 'Spam'].map((text, index) => (
                <ListItem button key={text}>
                  <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                  <ListItemText primary={text} />
                </ListItem>
              ))}
            </List> */}
          </Drawer>
          <main
            style={device === 'mobile' ? { padding: 0 } : {}}
            className={clsx(classes.content, {
              [classes.contentShift]: openDrawer,
            })}
          >
            <div className={classes.drawerHeader} />
            <div
              style={device === 'mobile' ? {
                width: '80vw',
                margin: 'auto',
                textAlign: 'center',
                marginLeft: 40,
              } : {
                width: '80vw',
                margin: 'auto',
                textAlign: 'center',
              }}
            >
              <Paper
                style={{
                  padding: 24,
                  paddingBottom: 10,
                  backgroundColor: '#151A1F',
                  borderRadius: 6,
                  // textAlign: 'center',
                  color: '#FFAA00BF',
                  fontFamily: 'avenir',
                  lineHeight: 1.43
                }}
              >
                <p><strong><h2><u>What is CheeseComputer?</u></h2></strong></p>
                <p>
                  <em>
                    "On the surface, Cheese is just corny jokes and play on words, but on a
                    deeper level it’s much more than that. Cheese is about creativity,
                    originality, and innovation. It’s about finding your passion and running with
                    it." -Ned, Founder
                  </em>
                </p>
                <p><strong><h3><u>Project Mission</u></h3></strong></p>
                <p>
                The CheeseComputer™ Project is a multiple-collection NFT project on the Solana blockchain with a focus of uniting communities through storytelling, providing a networking platform for collaborating and working in web3, and strengthening the blockchain through project-ran and community-ran validators.
                </p>
                <p><strong><h3><u>How it started:</u></h3></strong></p>
                <p>
                  CheeseComputer™ started off as an online store that sold computer mice
                  with cheesy names including “Clickey Mouse,” “Love at First Swipe,” “Rock
                  and Scroll,” and many other puns.
                </p>

                <p>
                  This website soon rebranded and increased its focus on the puns. There
                  were now phone grips, laptop sleeves, and laptop stickers with not just pun
                  names, but also pun designs. Artwork similar to a <a style={{ color: '#FFAA00' }} href="https://en.wikipedia.org/wiki/Rebus" target="_blank">rebus puzzle</a> that featured a unique pun was now the new focus of CheeseComputer™.
                  During this time also came the birth of Cheese the computer mouse, and
                  the CheeseComputer™ Etsy shop which features several pun designs on
                  mugs.
                </p>
                <br />

                <p><strong><h3><u>Journey into Web3.0</u></h3></strong></p>
                <p>
                  As time went on, the talk about NFTs became popular. Child prodigies like Benyamin Ahmed were explaining to Oxford students what NFTs were. YouTube cryptocurrency legends like CryptoCasey were discussing the potential use and future of NFTs and cryptocurrency in general. Additionally, the Bored Ape Yacht Club NFT collection was being used as a status symbol by celebrities.
                </p>
                <p>
                  Times were changing and CheeseComputer™ needed to be a part of it. They were going to have some fun with NFTs. Collections that can combine to create a new NFT, NFTs that grant access to web3 related courses, and NFTs with easy to grasp real-world utility. All while staying true to the cheesy pun theme.
                </p>

                <br />
                <div className="flexteam">
                  <div className="team-member">
                    <img src="/dedebrux.jpg" alt="" />
                    <a href="https://www.instagram.com/dedebrux/?hl=en" target="_blank" rel="noreferrer">Dedebrux</a>
                    <h2>Lead Artist</h2>
                    <p>
                    With over 8 years of experience in illustration and graphic design, Dedebrux brings the story and collections of Cheese to life.
                    </p>
                  </div>
                  <div className="team-member">
                    <img src="/roberto.jpg" alt="" />
                    <a href="https://lazardigital.io" target="_blank" rel="noreferrer">Roberto</a>
                    <h2>Advisor / Lead Developer</h2>
                    <p>
                    Experience in Solana, Ethereum, and Aptos smart contract development and an advisor of many successful NFT projects.                    </p>
                  </div>
                  <div className="team-member">
                    <img src="/Ned.png" alt="" />
                    <a href="https://twitter.com/CheesetheMouse8" target="_blank" rel="noreferrer">Ned</a>
                    <h2>Founder</h2>
                    <p>
                    The man behind the mouse. A cheesy guy with big dreams and a will to see it through.</p>
                  </div>
                </div>

                <p><strong><h3><u>Looking Ahead</u></h3></strong></p>
                <p>
                  We are still early in the NFT space. Where we are now with NFTs is where
                  the internet was in the late 90s. With this is mind, CheeseComputer™ is

                  looking at where we can make our mark next. We’re honored to be a part of
                  this growing technology and look forward to the potential it holds for future
                  innovation.
                </p>
                {/* <p>NFT stands for non-fungible token. <br /> <br /> A non-fungible item is not exchangeable; this could be due to subjective or objective value. For example, a pet cat would be non-fungible due to subjective value. Michelle’s cat cannot be exchanged for Robert’s cat, they hold different values to their owners. The deeds to two houses can be objectively non-fungible. Even if the houses have the same monetary value, their location, landscape, and other factors create different values. <br /> <br /> A token is anything that represents something else. You may have heard someone refer to a gift as a token of his or her love, or perhaps you’ve been to an arcade that required you to give your money in exchange for their coins (tokens) in order to play the arcade games. The tokens you used to play the arcade games are a representation of your currency while you’re at the arcade.</p>
              <p><strong>Altogether, <u>an NFT is a digital representation of a one-of-a-kind asset</u>.</strong></p>
              <p><br /> &nbsp;<strong><h2>What’s the Potential for NFTs?</h2></strong></p>
              <p>Admittedly, I used to be a skeptic. I wondered why people would pay for these images or files that could easily be screenshot, copied, and reposted by anyone. Being doubtful about this new technology made me want to learn more about it and see what all the fuss what about. After some time in this space, I’ve learned a few important concepts behind NFTs.</p>
              <p><br /> The most interesting concept behind NFTs is the proof of ownership. NFTs use blockchain technology to confirm the owner of an asset. A blockchain is a public record (ledger) of all transactions for a specific cryptocurrency. For example, the Ethereum blockchain displays a public record of all Ether (Ethereum’s cryptocurrency) transactions from one wallet address to another. Blockchains are shared on a decentralized network. This means there is no central group that controls the blockchain. Anyone can access it, no one can change it. <br /> <br /> After more time of researching and learning about the NFT space. I started to see what these could be used for in the real world. <strong><u>NFT technology can be for anything that requires proof of ownership. College degrees, passports, drivers’ licenses, the deed to a property, event tickets and so many more everyday items could become NFTs.<br /> <br /> </u></strong>The benefit would be having a digital and verifiable copy of any important documents you own. Convenience is key to innovation.</p>
              <p><br /> <strong><h2>What is CheeseComputer?</h2></strong><strong> </strong> Okay I know, this is what you really came for. What is CheeseComputer?<br /> <br /> Well, it started from the bright idea of making a computer mouse into an actual mouse. Then deciding that an actual mouse wouldn’t want to be connected to a computer, so we turned the computer into cheese. Makes sense, right?<br /> <br /> <u>CheeseComputer is the center for pun inspired art and we’re avid supporters of uncensored comedy.</u> <br /> <br /> We are a small team of less than 5 people who are excited about the future of NFTs and are currently working towards a real-world reoccurring event that would use NFTs for entry and encourages people who aren’t familiar with NFTs or cryptocurrency to attend these events. More details on this during the Block Cheese Phase.</p>
           */}
              </Paper>
            </div>
          </main>
        </div>
      ) : (
        <>
          <Header />
          <Container style={{ marginTop: 100 }}>
            <Container maxWidth="md" style={{ position: 'relative' }}>
              <Paper
                style={{
                  padding: 24,
                  paddingBottom: 10,
                  backgroundColor: '#151A1F',
                  borderRadius: 6,
                  // textAlign: 'center',
                  color: '#FFAA00BF',
                  fontFamily: 'avenir',
                  lineHeight: 1.43
                }}
              >
                <p><strong><h2><u>What is CheeseComputer?</u></h2></strong></p>
                <p>
                  <em>
                    "On the surface, Cheese is just corny jokes and play on words, but on a
                    deeper level it’s much more than that. Cheese is about creativity,
                    originality, and innovation. It’s about finding your passion and running with
                    it." -Ned, Founder
                  </em>
                </p>
                <p><strong><h3><u>Project Mission</u></h3></strong></p>
                <p>
                The CheeseComputer™ Project is a multiple-collection NFT project on the Solana blockchain with a focus of uniting communities through storytelling, providing a networking platform for collaborating and working in web3, and strengthening the blockchain through project-ran and community-ran validators.
                </p>
                <p><strong><h3><u>How it started:</u></h3></strong></p>
                <p>
                  CheeseComputer™ started off as an online store that sold computer mice
                  with cheesy names including “Clickey Mouse,” “Love at First Swipe,” “Rock
                  and Scroll,” and many other puns.
                </p>

                <p>
                  This website soon rebranded and increased its focus on the puns. There
                  were now phone grips, laptop sleeves, and laptop stickers with not just pun
                  names, but also pun designs. Artwork similar to a <a style={{ color: '#FFAA00' }} href="https://en.wikipedia.org/wiki/Rebus" target="_blank">rebus puzzle</a> that featured a unique pun was now the new focus of CheeseComputer™.
                  During this time also came the birth of Cheese the computer mouse, and
                  the CheeseComputer™ Etsy shop which features several pun designs on
                  mugs.
                </p>
                <br />

                <p><strong><h3><u>Journey into Web3.0</u></h3></strong></p>
                <p>
                  As time went on, the talk about NFTs became popular. Child prodigies like Benyamin Ahmed were explaining to Oxford students what NFTs were. YouTube cryptocurrency legends like CryptoCasey were discussing the potential use and future of NFTs and cryptocurrency in general. Additionally, the Bored Ape Yacht Club NFT collection was being used as a status symbol by celebrities.
                </p>
                <p>Times were changing and CheeseComputer™ needed to be a part of it. They were going to have some fun with NFTs. Collections that can combine to create a new NFT, NFTs that grant access to web3 related courses, and NFTs with easy to grasp real-world utility. All while staying true to the cheesy pun theme.</p>

             
                <br />
                <div className="flexteam">
                  <div className="team-member">
                    <img src="/dedebrux.jpg" alt="" />
                    <a href="https://www.instagram.com/dedebrux/?hl=en" target="_blank" rel="noreferrer">Dedebrux</a>
                    <h2>Lead Artist</h2>
                    <p>
                    With over 8 years of experience in illustration and graphic design, Dedebrux brings the story and collections of Cheese to life.
                    </p>
                  </div>
                  <div className="team-member">
                    <img src="/roberto.jpg" alt="" />
                    <a href="https://lazardigital.io" target="_blank" rel="noreferrer">Roberto</a>
                    <h2>Advisor / Lead Developer</h2>
                    <p>
                    Experience in Solana, Ethereum, and Aptos smart contract development and an advisor of many successful NFT projects.                    </p>
                  </div>
                  <div className="team-member">
                    <img src="/Ned.png" alt="" />
                    <a href="https://twitter.com/CheesetheMouse8" target="_blank" rel="noreferrer">Ned</a>
                    <h2>Founder</h2>
                    <p>
                    The man behind the mouse. A cheesy guy with big dreams and a will to see it through.</p>
                  </div>
                </div>

                <p><strong><h3><u>Looking Ahead</u></h3></strong></p>
                <p>
                  We are still early in the NFT space. Where we are now with NFTs is where
                  the internet was in the late 90s. With this is mind, CheeseComputer™ is

                  looking at where we can make our mark next. We’re honored to be a part of
                  this growing technology and look forward to the potential it holds for future
                  innovation.
                </p>
                {/* <p>NFT stands for non-fungible token. <br /> <br /> A non-fungible item is not exchangeable; this could be due to subjective or objective value. For example, a pet cat would be non-fungible due to subjective value. Michelle’s cat cannot be exchanged for Robert’s cat, they hold different values to their owners. The deeds to two houses can be objectively non-fungible. Even if the houses have the same monetary value, their location, landscape, and other factors create different values. <br /> <br /> A token is anything that represents something else. You may have heard someone refer to a gift as a token of his or her love, or perhaps you’ve been to an arcade that required you to give your money in exchange for their coins (tokens) in order to play the arcade games. The tokens you used to play the arcade games are a representation of your currency while you’re at the arcade.</p>
              <p><strong>Altogether, <u>an NFT is a digital representation of a one-of-a-kind asset</u>.</strong></p>
              <p><br /> &nbsp;<strong><h2>What’s the Potential for NFTs?</h2></strong></p>
              <p>Admittedly, I used to be a skeptic. I wondered why people would pay for these images or files that could easily be screenshot, copied, and reposted by anyone. Being doubtful about this new technology made me want to learn more about it and see what all the fuss what about. After some time in this space, I’ve learned a few important concepts behind NFTs.</p>
              <p><br /> The most interesting concept behind NFTs is the proof of ownership. NFTs use blockchain technology to confirm the owner of an asset. A blockchain is a public record (ledger) of all transactions for a specific cryptocurrency. For example, the Ethereum blockchain displays a public record of all Ether (Ethereum’s cryptocurrency) transactions from one wallet address to another. Blockchains are shared on a decentralized network. This means there is no central group that controls the blockchain. Anyone can access it, no one can change it. <br /> <br /> After more time of researching and learning about the NFT space. I started to see what these could be used for in the real world. <strong><u>NFT technology can be for anything that requires proof of ownership. College degrees, passports, drivers’ licenses, the deed to a property, event tickets and so many more everyday items could become NFTs.<br /> <br /> </u></strong>The benefit would be having a digital and verifiable copy of any important documents you own. Convenience is key to innovation.</p>
              <p><br /> <strong><h2>What is CheeseComputer?</h2></strong><strong> </strong> Okay I know, this is what you really came for. What is CheeseComputer?<br /> <br /> Well, it started from the bright idea of making a computer mouse into an actual mouse. Then deciding that an actual mouse wouldn’t want to be connected to a computer, so we turned the computer into cheese. Makes sense, right?<br /> <br /> <u>CheeseComputer is the center for pun inspired art and we’re avid supporters of uncensored comedy.</u> <br /> <br /> We are a small team of less than 5 people who are excited about the future of NFTs and are currently working towards a real-world reoccurring event that would use NFTs for entry and encourages people who aren’t familiar with NFTs or cryptocurrency to attend these events. More details on this during the Block Cheese Phase.</p>
           */}
              </Paper>
            </Container>
          </Container>
        </>
      )}
      <Footer />
    </div>


    //     <div>
    //       <Header />

    //       <Container style={{ marginTop: 100 }}>
    //         <Container maxWidth="md" style={{ position: 'relative' }}>
    //           <Paper
    //             style={{
    //               padding: 24,
    //               paddingBottom: 10,
    //               backgroundColor: '#151A1F',
    //               borderRadius: 6,
    //               // textAlign: 'center',
    //               color: '#FFAA00BF',
    //               fontFamily: 'avenir',
    //               lineHeight: 1.43
    //             }}
    //           >
    //               <p><strong><h2><u>What is CheeseComputer?</u></h2></strong></p>
    //               <p>
    //                 <em>
    //                   "On the surface, Cheese is just corny jokes and play on words, but on a
    //                   deeper level it’s much more than that. Cheese is about creativity,
    //                   originality, and innovation. It’s about finding your passion and running with
    //                   it." -Ned, Founder
    //                 </em>
    //               </p>

    //               <p><strong><h3><u>How it started:</u></h3></strong></p>
    //               <p>
    //                 CheeseComputer™ started off as an online store that sold computer mice
    //                 with cheesy names including “Clickey Mouse,” “Love at First Swipe,” “Rock
    //                 and Scroll,” and many other puns.
    //               </p>

    //               <p>
    //               This website soon rebranded and increased its focus on the puns. There
    // were now phone grips, laptop sleeves, and laptop stickers with not just pun
    // names, but also pun designs. Artwork similar to a <a style={{ color: '#FFAA00' }} href="https://en.wikipedia.org/wiki/Rebus" target="_blank">rebus puzzle</a> that featured a unique pun was now the new focus of CheeseComputer™.
    // During this time also came the birth of Cheese the computer mouse, and
    // the CheeseComputer™ Etsy shop which features several pun designs on
    // mugs.
    //               </p>
    //               <br/>

    //               <p><strong><h3><u>Journey into Web3.0</u></h3></strong></p>
    //               <p>
    //               As time went on, the talk about NFTs became popular. Child prodigies like
    // Benyamin Ahmed were explaining to Oxford students what NFTs were.
    // YouTube cryptocurrency legends like CryptoCasey were discussing the
    // potential use and future of NFTs and cryptocurrency in general.
    // Additionally, the Bored Ape Yacht Club NFT collection was being used as a
    // status symbol by celebrities.
    //               </p>
    //               <p>Times were changing and CheeseComputer™ needed to be a part of it. The
    // founder, Ned, reached out to his friend Fish, a full-stack web developer, Roberto, a Solana builder,
    //and the artist who worked with Ned when the company was solely in web2, Dedebrux (IG: <a style={{ color: '#FFAA00' }} href="https://www.instagram.com/dedebrux" target="_blank">@dedebrux</a>), and explained the idea.</p>

    //               <p>They were going to have some fun with NFTs. Collections that can combine
    // to create a new NFT, NFTs that grant access to useful web3 related courses,
    // and NFTs that could be used for admission to real life events. All while
    // staying true to the cheesy pun theme.</p>
    // <br/>


    // <p><strong><h3><u>Looking Ahead</u></h3></strong></p>
    //               <p>
    //               We are still early in the NFT space. Where we are now with NFTs is where
    // the internet was in the late 90s. With this is mind, CheeseComputer™ is

    // looking at where we can make our mark next. We’re honored to be a part of
    // this growing technology and look forward to the potential it holds for future
    // innovation.
    //               </p>
    //               {/* <p>NFT stands for non-fungible token. <br /> <br /> A non-fungible item is not exchangeable; this could be due to subjective or objective value. For example, a pet cat would be non-fungible due to subjective value. Michelle’s cat cannot be exchanged for Robert’s cat, they hold different values to their owners. The deeds to two houses can be objectively non-fungible. Even if the houses have the same monetary value, their location, landscape, and other factors create different values. <br /> <br /> A token is anything that represents something else. You may have heard someone refer to a gift as a token of his or her love, or perhaps you’ve been to an arcade that required you to give your money in exchange for their coins (tokens) in order to play the arcade games. The tokens you used to play the arcade games are a representation of your currency while you’re at the arcade.</p>
    //               <p><strong>Altogether, <u>an NFT is a digital representation of a one-of-a-kind asset</u>.</strong></p>
    //               <p><br /> &nbsp;<strong><h2>What’s the Potential for NFTs?</h2></strong></p>
    //               <p>Admittedly, I used to be a skeptic. I wondered why people would pay for these images or files that could easily be screenshot, copied, and reposted by anyone. Being doubtful about this new technology made me want to learn more about it and see what all the fuss what about. After some time in this space, I’ve learned a few important concepts behind NFTs.</p>
    //               <p><br /> The most interesting concept behind NFTs is the proof of ownership. NFTs use blockchain technology to confirm the owner of an asset. A blockchain is a public record (ledger) of all transactions for a specific cryptocurrency. For example, the Ethereum blockchain displays a public record of all Ether (Ethereum’s cryptocurrency) transactions from one wallet address to another. Blockchains are shared on a decentralized network. This means there is no central group that controls the blockchain. Anyone can access it, no one can change it. <br /> <br /> After more time of researching and learning about the NFT space. I started to see what these could be used for in the real world. <strong><u>NFT technology can be for anything that requires proof of ownership. College degrees, passports, drivers’ licenses, the deed to a property, event tickets and so many more everyday items could become NFTs.<br /> <br /> </u></strong>The benefit would be having a digital and verifiable copy of any important documents you own. Convenience is key to innovation.</p>
    //               <p><br /> <strong><h2>What is CheeseComputer?</h2></strong><strong> </strong> Okay I know, this is what you really came for. What is CheeseComputer?<br /> <br /> Well, it started from the bright idea of making a computer mouse into an actual mouse. Then deciding that an actual mouse wouldn’t want to be connected to a computer, so we turned the computer into cheese. Makes sense, right?<br /> <br /> <u>CheeseComputer is the center for pun inspired art and we’re avid supporters of uncensored comedy.</u> <br /> <br /> We are a small team of less than 5 people who are excited about the future of NFTs and are currently working towards a real-world reoccurring event that would use NFTs for entry and encourages people who aren’t familiar with NFTs or cryptocurrency to attend these events. More details on this during the Block Cheese Phase.</p>
    //            */}
    //           </Paper>
    //         </Container>
    //       </Container>
    //       {/* <footer>
    //         <p>
    //           Copyright © 2022, CheeseComputer
    //         </p>
    //       </footer> */}
    //       <Footer />
    //     </div>
  );
};

export default About;
