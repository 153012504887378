import { Fragment, useMemo, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import { Container } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';


const Footer = () => {
  return (
    <footer>
      <p>
        Copyright © 2022, CheeseComputer
      </p>
      <p>
        <a style={{ textDecoration: 'none', color: '#FFAA00' }} href="https://twitter.com/CheeseComp_NFT" target="_blank">Twitter</a><br/>
        <a style={{ textDecoration: 'none', color: '#FFAA00' }} href="https://www.etsy.com/shop/CheeseComputer" target="_blank">Etsy</a><br/>
        <a style={{ textDecoration: 'none', color: '#FFAA00' }} href="https://www.instagram.com/cheesecomputer8/" target="_blank">Instagram</a><br/>
        <a style={{ textDecoration: 'none', color: '#FFAA00' }} href="https://cheesecomputer8.medium.com/" target="_blank">Medium Blogs</a><br/>
      </p>
    </footer>
  );
};

export default Footer;
